<template>
  <div class="w-100 pt-4">
    <b-container fluid="xl">
      <div class="mb-3">
        <div class="h3 mb-0">
          Настройки
        </div>
      </div>

      <div class="mt-2">
        <router-link to="/banner-management">
          Управление баннерами
        </router-link>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Settings',
};
</script>

<style scoped>

</style>
